import React, { useEffect } from 'react';
import './Navbar.css';
import ConnectButton from '../../Web/ConnectButton';
import { useWallet } from '../../context/WalletContext';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const { status, setStatus, connectedAddress } = useWallet();

    useEffect(() => {
        if (!connectedAddress) {
            setStatus(false);
            return;
        }
        postWalletAddress(connectedAddress);
    }, [connectedAddress, setStatus]);

    const postWalletAddress = async (address) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API}holder-addresses`, {
                holder_address: String(address).toLowerCase()
            });
            setStatus(response.data.status);
            console.log('Response:', response.data);
        } catch (error) {
            console.error('Error posting wallet address:', error);
            toast.error('Failed to send wallet address.', { autoClose: 1000 });
        }
    };

    return (
        <div>
            <div className='navbar-window'>
                <Link to="/" className='logo-window'>
                    <img src="../../assets/logo.png" alt="Logo" />
                    <strong className='max-[700px]:hidden'>Flash Technologies</strong>
                </Link>
                <ConnectButton />
            </div>
        </div>
    );
};

export default Navbar;
