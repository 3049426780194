import React, { createContext, useState, useContext, useEffect } from 'react';
import { useAccount } from 'wagmi';

// Create a Context for the wallet response state
const WalletContext = createContext();

// Create a Provider component
export const WalletProvider = ({ children }) => {
    // const [responseStatus, setResponseStatus] = useState(false);
    const [status, setStatus] = useState(false)
    const [formStatus, setFormStatus] = useState(false)
    const [account, setAccount] = useState();
    const { isConnected, address } = useAccount();
    const [connectedAddress, setConnectedAddress] = useState('');

    useEffect(() => {
        if (isConnected && address) {
            setConnectedAddress(address);
        } else {
            setConnectedAddress('');
            setStatus(false)
        }
    }, [isConnected, address]);

    return (
        <WalletContext.Provider value={{ status, setStatus, connectedAddress,formStatus,setFormStatus }}>
            {children}
        </WalletContext.Provider>
    );
};

export const useWallet = () => useContext(WalletContext);